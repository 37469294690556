const data = [
   {
      cityName: "bangalore",
      cityData: [
         {
            url: "https://playo.co/venues/bangalore/sports/all",
            title: "Sports Complexes in Bangalore"
         },
         {
            url: "https://playo.co/venues/bangalore/sports/badminton",
            title: "Badminton Courts in Bangalore"
         },
         {
            url: "https://playo.co/venues/bangalore/sports/football",
            title: "Football Grounds in Bangalore"
         },
         {
            url: "https://playo.co/venues/bangalore/sports/cricket",
            title: "Cricket Grounds in Bangalore"
         },
         {
            url: "https://playo.co/venues/bangalore/sports/tennis",
            title: "Tennis Courts in Bangalore"
         },
         {
            url: "https://playo.co/venues/bangalore/sports/basketball",
            title: "Basketball Courts in Bangalore"
         },
         {
            url: "https://playo.co/venues/bangalore/sports/table_tennis",
            title: "Table Tennis Clubs in Bangalore"
         },
         {
            url: "https://playo.co/venues/bangalore/sports/volleyball",
            title: "Volleyball Courts in Bangalore"
         },
         {
            url: "https://playo.co/venues/bangalore/sports/swimming",
            title: "Swimming Pools in Bangalore"
         }
      ]
   },

   {
      cityName: "chennai",
      cityData: [
         {
            url: "https://playo.co/venues/chennai/sports/all",
            title: "Sports Complexes in Chennai"
         },
         {
            url: "https://playo.co/venues/chennai/sports/badminton",
            title: "Badminton Courts in Chennai"
         },
         {
            url: "https://playo.co/venues/chennai/sports/football",
            title: "Football Grounds in Chennai"
         },
         {
            url: "https://playo.co/venues/chennai/sports/cricket",
            title: "Cricket Grounds in Chennai"
         },
         {
            url: "https://playo.co/venues/chennai/sports/tennis",
            title: "Tennis Courts in Chennai"
         },
         {
            url: "https://playo.co/venues/chennai/sports/basketball",
            title: "Basketball Courts in Chennai"
         },
         {
            url: "https://playo.co/venues/chennai/sports/table_tennis",
            title: "Table Tennis Clubs in Chennai"
         },
         {
            url: "https://playo.co/venues/chennai/sports/volleyball",
            title: "Volleyball Courts in Chennai"
         },
         {
            url: "https://playo.co/venues/chennai/sports/swimming",
            title: "Swimming Pools in Chennai"
         }
      ]
   },
   {
      cityName: "hyderabad",
      cityData: [
         {
            url: "https://playo.co/venues/hyderabad/sports/all",
            title: "Sports Complexes in Hyderabad"
         },
         {
            url: "https://playo.co/venues/hyderabad/sports/badminton",
            title: "Badminton Courts in Hyderabad"
         },
         {
            url: "https://playo.co/venues/hyderabad/sports/football",
            title: "Football Grounds in Hyderabad"
         },
         {
            url: "https://playo.co/venues/hyderabad/sports/cricket",
            title: "Cricket Grounds in Hyderabad"
         },
         {
            url: "https://playo.co/venues/hyderabad/sports/tennis",
            title: "Tennis Courts in Hyderabad"
         },
         {
            url: "https://playo.co/venues/hyderabad/sports/basketball",
            title: "Basketball Courts in Hyderabad"
         },
         {
            url: "https://playo.co/venues/hyderabad/sports/table_tennis",
            title: "Table Tennis Clubs in Hyderabad"
         },
         {
            url: "https://playo.co/venues/hyderabad/sports/volleyball",
            title: "Volleyball Courts in Hyderabad"
         },
         {
            url: "https://playo.co/venues/hyderabad/sports/swimming",
            title: "Swimming Pools in Hyderabad"
         }
      ]
   },
   {
      cityName: "pune",
      cityData: [
         {
            url: "https://playo.co/venues/pune/sports/all",
            title: "Sports Complexes in Pune"
         },
         {
            url: "https://playo.co/venues/pune/sports/badminton",
            title: "Badminton Courts in Pune"
         },
         {
            url: "https://playo.co/venues/pune/sports/football",
            title: "Football Grounds in Pune"
         },
         {
            url: "https://playo.co/venues/pune/sports/cricket",
            title: "Cricket Grounds in Pune"
         },
         {
            url: "https://playo.co/venues/pune/sports/tennis",
            title: "Tennis Courts in Pune"
         },
         {
            url: "https://playo.co/venues/pune/sports/basketball",
            title: "Basketball Courts in Pune"
         },
         {
            url: "https://playo.co/venues/pune/sports/table_tennis",
            title: "Table Tennis Clubs in Pune"
         },
         {
            url: "https://playo.co/venues/pune/sports/volleyball",
            title: "Volleyball Courts in Pune"
         },
         {
            url: "https://playo.co/venues/pune/sports/swimming",
            title: "Swimming Pools in Pune"
         }
      ]
   },
   {
      cityName: "vijayawada",
      cityData: [
         {
            url: "https://playo.co/venues/vijayawada/sports/all",
            title: "Sports Complexes in Vijayawada"
         },
         {
            url: "https://playo.co/venues/vijayawada/sports/badminton",
            title: "Badminton Courts in Vijayawada"
         },
         {
            url: "https://playo.co/venues/vijayawada/sports/football",
            title: "Football Grounds in Vijayawada"
         },
         {
            url: "https://playo.co/venues/vijayawada/sports/cricket",
            title: "Cricket Grounds in Vijayawada"
         },
         {
            url: "https://playo.co/venues/vijayawada/sports/tennis",
            title: "Tennis Courts in Vijayawada"
         },
         {
            url: "https://playo.co/venues/vijayawada/sports/basketball",
            title: "Basketball Courts in Vijayawada"
         },
         {
            url: "https://playo.co/venues/vijayawada/sports/table_tennis",
            title: "Table Tennis Clubs in Vijayawada"
         },
         {
            url: "https://playo.co/venues/vijayawada/sports/volleyball",
            title: "Volleyball Courts in Vijayawada"
         }
      ]
   },
   {
      cityName: "mumbai",
      cityData: [
         {
            url: "https://playo.co/venues/mumbai/sports/all",
            title: "Sports Complexes in Mumbai"
         },
         {
            url: "https://playo.co/venues/mumbai/sports/badminton",
            title: "Badminton Courts in Mumbai"
         },
         {
            url: "https://playo.co/venues/mumbai/sports/football",
            title: "Football Grounds in Mumbai"
         },
         {
            url: "https://playo.co/venues/mumbai/sports/cricket",
            title: "Cricket Grounds in Mumbai"
         },
         {
            url: "https://playo.co/venues/mumbai/sports/tennis",
            title: "Tennis Courts in Mumbai"
         },
         {
            url: "https://playo.co/venues/mumbai/sports/basketball",
            title: "Basketball Courts in Mumbai"
         },
         {
            url: "https://playo.co/venues/mumbai/sports/table_tennis",
            title: "Table Tennis Clubs in Mumbai"
         },
         {
            url: "https://playo.co/venues/mumbai/sports/volleyball",
            title: "Volleyball Courts in Mumbai"
         },
         {
            url: "https://playo.co/venues/mumbai/sports/swimming",
            title: "Swimming Pools in Mumbai"
         }
      ]
   },
   {
      cityName: "Delhi NCR",
      cityData: [
         {
            url: "https://playo.co/venues/delhi-ncr/sports/all",
            title: "Sports Complexes in Delhi NCR"
         },
         {
            url: "https://playo.co/venues/delhi-ncr/sports/badminton",
            title: "Badminton Courts in Delhi NCR"
         },
         {
            url: "https://playo.co/venues/delhi-ncr/sports/football",
            title: "Football Grounds in Delhi NCR"
         },
         {
            url: "https://playo.co/venues/delhi-ncr/sports/cricket",
            title: "Cricket Grounds in Delhi NCR"
         },
         {
            url: "https://playo.co/venues/delhi-ncr/sports/tennis",
            title: "Tennis Courts in Delhi NCR"
         },
         {
            url: "https://playo.co/venues/delhi-ncr/sports/basketball",
            title: "Basketball Courts in Delhi NCR"
         },
         {
            url: "https://playo.co/venues/delhi-ncr/sports/table_tennis",
            title: "Table Tennis Clubs in Delhi NCR"
         },
         {
            url: "https://playo.co/venues/delhi-ncr/sports/volleyball",
            title: "Volleyball Courts in Delhi NCR"
         },
         {
            url: "https://playo.co/venues/delhi-ncr/sports/swimming",
            title: "Swimming Pools in Delhi NCR"
         }
      ]
   },
   {
      cityName: "Visakhapatnam",
      cityData: [
         {
            url: "https://playo.co/venues/Visakhapatnam/sports/all",
            title: "Sports Complexes in Visakhapatnam"
         },
         {
            url: "https://playo.co/venues/Visakhapatnam/sports/badminton",
            title: "Badminton Courts in Visakhapatnam"
         },
         {
            url: "https://playo.co/venues/Visakhapatnam/sports/football",
            title: "Football Grounds in Visakhapatnam"
         },
         {
            url: "https://playo.co/venues/Visakhapatnam/sports/cricket",
            title: "Cricket Grounds in Visakhapatnam"
         },
         {
            url: "https://playo.co/venues/Visakhapatnam/sports/tennis",
            title: "Tennis Courts in Visakhapatnam"
         },
         {
            url: "https://playo.co/venues/Visakhapatnam/sports/basketball",
            title: "Basketball Courts in Visakhapatnam"
         },
         {
            url: "https://playo.co/venues/Visakhapatnam/sports/table_tennis",
            title: "Table Tennis Clubs in Visakhapatnam"
         },
         {
            url: "https://playo.co/venues/Visakhapatnam/sports/volleyball",
            title: "Volleyball Courts in Visakhapatnam"
         },
         {
            url: "https://playo.co/venues/Visakhapatnam/sports/swimming",
            title: "Swimming Pools in Visakhapatnam"
         }
      ]
   },
   {
      cityName: "guntur",
      cityData: [
         {
            url: "https://playo.co/venues/guntur/sports/all",
            title: "Sports Complexes in Guntur"
         },
         {
            url: "https://playo.co/venues/guntur/sports/badminton",
            title: "Badminton Courts in Guntur"
         },
         {
            url: "https://playo.co/venues/guntur/sports/football",
            title: "Football Grounds in Guntur"
         },
         {
            url: "https://playo.co/venues/guntur/sports/cricket",
            title: "Cricket Grounds in Guntur"
         },
         {
            url: "https://playo.co/venues/guntur/sports/tennis",
            title: "Tennis Courts in Guntur"
         },
         {
            url: "https://playo.co/venues/guntur/sports/basketball",
            title: "Basketball Courts in Guntur"
         },
         {
            url: "https://playo.co/venues/guntur/sports/table_tennis",
            title: "Table Tennis Clubs in Guntur"
         },
         {
            url: "https://playo.co/venues/guntur/sports/volleyball",
            title: "Volleyball Courts in Guntur"
         },
         {
            url: "https://playo.co/venues/guntur/sports/swimming",
            title: "Swimming Pools in Guntur"
         }
      ]
   },
   {
      cityName: "Kochi",
      cityData: [
         {
            url: "https://playo.co/venues/Kochi/sports/all",
            title: "Sports Complexes in Kochi"
         },
         {
            url: "https://playo.co/venues/Kochi/sports/badminton",
            title: "Badminton Courts in Kochi"
         },
         {
            url: "https://playo.co/venues/Kochi/sports/football",
            title: "Football Grounds in Kochi"
         },
         {
            url: "https://playo.co/venues/Kochi/sports/cricket",
            title: "Cricket Grounds in Kochi"
         },
         {
            url: "https://playo.co/venues/Kochi/sports/tennis",
            title: "Tennis Courts in Kochi"
         },
         {
            url: "https://playo.co/venues/Kochi/sports/basketball",
            title: "Basketball Courts in Kochi"
         },
         {
            url: "https://playo.co/venues/Kochi/sports/table_tennis",
            title: "Table Tennis Clubs in Kochi"
         },
         {
            url: "https://playo.co/venues/Kochi/sports/volleyball",
            title: "Volleyball Courts in Kochi"
         },
         {
            url: "https://playo.co/venues/Kochi/sports/swimming",
            title: "Swimming Pools in Kochi"
         }
      ]
   },
   {
      cityName: "dubai",
      image: 'https://flagcdn.com/16x12/ae.png',
      cityData: [
         {
            url: "https://playo.co/venues/dubai/sports/all",
            title: "Sports Complexes in Dubai"
         },
         {
            url: "https://playo.co/venues/dubai/sports/badminton",
            title: "Badminton Courts in Dubai"
         },
         {
            url: "https://playo.co/venues/dubai/sports/football",
            title: "Football Grounds in Dubai"
         },
         {
            url: "https://playo.co/venues/dubai/sports/cricket",
            title: "Cricket Grounds in Dubai"
         },
         {
            url: "https://playo.co/venues/dubai/sports/tennis",
            title: "Tennis Courts in Dubai"
         },
         {
            url: "https://playo.co/venues/dubai/sports/basketball",
            title: "Basketball Courts in Dubai"
         },
         {
            url: "https://playo.co/venues/dubai/sports/table_tennis",
            title: "Table Tennis Clubs in Dubai"
         },
         {
            url: "https://playo.co/venues/dubai/sports/volleyball",
            title: "Volleyball Courts in Dubai"
         },
         {
            url: "https://playo.co/venues/dubai/sports/swimming",
            title: "Swimming Pools in Dubai"
         }
      ]
   },
   {
      cityName: "Qatar",
      image: 'https://flagcdn.com/16x12/qa.png',
      cityData: [
         {
            url: "https://playo.co/venues/Qatar/sports/all",
            title: "Sports Complexes in Qatar"
         },
         {
            url: "https://playo.co/venues/Qatar/sports/badminton",
            title: "Badminton Courts in Qatar"
         },
         {
            url: "https://playo.co/venues/Qatar/sports/football",
            title: "Football Grounds in Qatar"
         },
         {
            url: "https://playo.co/venues/Qatar/sports/cricket",
            title: "Cricket Grounds in Qatar"
         },
         {
            url: "https://playo.co/venues/Qatar/sports/tennis",
            title: "Tennis Courts in Qatar"
         },
         {
            url: "https://playo.co/venues/Qatar/sports/basketball",
            title: "Basketball Courts in Qatar"
         },
         {
            url: "https://playo.co/venues/Qatar/sports/table_tennis",
            title: "Table Tennis Clubs in Qatar"
         },
         {
            url: "https://playo.co/venues/Qatar/sports/volleyball",
            title: "Volleyball Courts in Qatar"
         },
         {
            url: "https://playo.co/venues/Qatar/sports/swimming",
            title: "Swimming Pools in Qatar"
         }
      ]
   },
   {
      cityName: "Australia",
      image: 'https://flagcdn.com/24x18/au.png',
      cityData: [
         {
            url: "https://playo.co/venues/australia/sports/all",
            title: "Sports Complexes in Australia"
         },
         {
            url: "https://playo.co/venues/australia/sports/badminton",
            title: "Badminton Courts in Australia"
         },
         {
            url: "https://playo.co/venues/australia/sports/football",
            title: "Football Grounds in Australia"
         },
         {
            url: "https://playo.co/venues/australia/sports/cricket",
            title: "Cricket Grounds in Australia"
         },
         {
            url: "https://playo.co/venues/australia/sports/tennis",
            title: "Tennis Courts in Australia"
         },
         {
            url: "https://playo.co/venues/australia/sports/basketball",
            title: "Basketball Courts in Australia"
         },
         {
            url: "https://playo.co/venues/australia/sports/table_tennis",
            title: "Table Tennis Clubs in Australia"
         },
         {
            url: "https://playo.co/venues/australia/sports/volleyball",
            title: "Volleyball Courts in Australia"
         },
         {
            url: "https://playo.co/venues/australia/sports/swimming",
            title: "Swimming Pools in Australia"
         }
      ]
   },
   {
      cityName: "Oman",
      image: 'https://flagcdn.com/24x18/om.png',
      cityData: [
         {
            url: "https://playo.co/venues/Oman/sports/all",
            title: "Sports Complexes in Oman"
         },
         {
            url: "https://playo.co/venues/Oman/sports/badminton",
            title: "Badminton Courts in Oman"
         },
         {
            url: "https://playo.co/venues/Oman/sports/football",
            title: "Football Grounds in Oman"
         },
         {
            url: "https://playo.co/venues/Oman/sports/cricket",
            title: "Cricket Grounds in Oman"
         },
         {
            url: "https://playo.co/venues/Oman/sports/tennis",
            title: "Tennis Courts in Oman"
         },
         {
            url: "https://playo.co/venues/Oman/sports/basketball",
            title: "Basketball Courts in Oman"
         },
         {
            url: "https://playo.co/venues/Oman/sports/table_tennis",
            title: "Table Tennis Clubs in Oman"
         },
         {
            url: "https://playo.co/venues/Oman/sports/volleyball",
            title: "Volleyball Courts in Oman"
         },
         {
            url: "https://playo.co/venues/Oman/sports/swimming",
            title: "Swimming Pools in Oman"
         }
      ]
   }
];

export default data;
